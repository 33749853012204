import React, { useState, useEffect } from 'react';
import logo from './images/newlogo.png';

const CopyrightFooter = () => {
  const [logoSize, setLogoSize] = useState('60px');

  useEffect(() => {
    const handleResize = () => {
      setLogoSize(window.innerWidth <= 600 ? '40px' : '60px')
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer style={{
      textAlign: 'center',
      padding: '40px 20px',
      color: '#000000',
      backgroundColor: '#088395',
      fontFamily: 'Arial, sans-serif'
    }}>
      {/* Company Logo */}
      <div style={{ marginBottom: '20px' }}>
        <img src={logo} alt="Company Logo" style={{ height: logoSize }} />
      </div>

      {/* Hyperlinks / Sections */}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap', marginBottom: '20px', fontSize: '1.5rem' }}>
        <a href="/about" style={{ textDecoration: 'none', color: '#000000' }}>About</a>
        <a href="/Solutions" style={{ textDecoration: 'none', color: '#000000' }}>Solutions</a>
        <a href="https://climformatics-demo-dashboard.web.app/" style={{ textDecoration: 'none', color: '#000000' }}>Demo</a>
        <a href="/media" style={{ textDecoration: 'none', color: '#000000' }}>Media</a>
        <a href="/terms-of-use" style={{ textDecoration: 'none', color: '#000000' }}>Terms & Conditions</a>
        <a href="/privacy-policy" style={{ textDecoration: 'none', color: '#000000' }}>Privacy Policy</a>
      </div>

      {/* Company Address and Social Links */}
      <div style={{ marginBottom: '20px' }}>
        <p style={{ margin: '0', fontSize: '1.2rem' }}>5381 Ridgewood Drive</p>
        <p style={{ margin: '0', fontSize: '1.2rem' }}>Fremont, California, 94555</p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
          <a href="https://www.linkedin.com/company/climformatics-inc/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin" style={{ fontSize: '24px', color: '#000000' }}></i>
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook" style={{ fontSize: '24px', color: '#000000' }}></i>
          </a>
          <a href="https://www.youtube.com/channel/UC4cRxm7rjPUDY13iDk4j_xQ" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube" style={{ fontSize: '24px', color: '#000000' }}></i>
          </a>
        </div>
        <p style={{ marginTop: '10px', fontSize: '1.2rem' }}>info@climformatics.com</p>
      </div>

      {/* Copyright */}
      <div>
        <p style={{ margin: '0', fontSize: '1.2rem' }}>&copy; 2024 Climformatics, Inc.</p>
      </div>
    </footer>
  );
}

export default CopyrightFooter;