import starImage from './images/stars.png';
import person1Image from './images/testimonial1.jpeg';
import person2Image from './images/peterwilliams_headshot.jpg';
import person3Image from './images/joseph_gallegos_headshot.jfif';

export const testimonials = [
    {
      starImg: starImage,
      quote:
        'Climformatics makes solar forecasting better, more granular and therefore more useful. This sort of solution will be key to resiliency - removing some of the uncertainty in current state of the art solar production.',
      personImg: person1Image,
      name: 'Danny Kennedy',
      company: 'Former Chief Energy Officer at New Energy Nexus',
    },
    {
      starImg: starImage,
      quote:
        'Climformatics\' genius lies in the fact that they have uniquely demonstrated the ability to bridge the "weather-climate gap" to an extent that allows a whole new class of decisions to be made on the basis of accurate forecasts. They have demonstrated highly localized (zip-code level) rainfall and temperature forecasts for timescales of 6-12 months that, for any given day, match or exceed the accuracy of the existing daily forecasts...This capability promises to be transformational in mitigating wildfire risk by providing greater preparation and mitigation time, and enabling improved optimization of electric utilities\' demand-supply and load balancing, power generation and operational logistics, significantly reducing risk and the cost for California\'s electricity ratepayers.',
      personImg: person2Image,
      name: 'Dr. Peter Williams',
      company:
        'Retd. IBM Distinguished Engineer',
    },
    {
      starImg: starImage,
      quote:
        'A service product we can purchase from Climformatics to assist in managing solar spikes, variability, and overcapacity across our farm client’s infrastructure and as a grid conditioning company smoothing out spikes and variability..These tools that Climformatics are developing will be a key element in our decision management processes, giving us the ability to better utilize additional megawatt hours that would have been curtailed daily.',
      personImg: person3Image,
      name: 'Joseph Gallegos',
      company:
        'CEO at Umida AG – Water & Natural Capital Asset Class Investor',
    },
  ];